.attribute {
    display: table;
    width: 100%;
}

.attribute_header_div {
    display: table;
    width: 100%;
}

.attribute_header {
    display: table-cell;
    width: 100%;
}

.attribute_header.value {
    display: table-cell;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 16px;
    color: #2A2A2A;
    width: auto;
}

.attribute_header.value_hidden {
    display: table-cell;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 16px;
    color: #F4F4F4;
    width: auto;
}

.attribute_header.label {
    display: table-caption;
    padding-top: 3px;
    padding-bottom: 3px;
    width: 175px;
    font-size: 13px;
    color: #AAAAAA;
}

.attribute.label {
    display: table-cell;
    padding-top: 6px;
    padding-bottom: 3px;
    width: 175px;
    min-width: 175px;
    font-size: 13px;
    color: #AAAAAA;
    vertical-align: top;
}

.attribute.label.empty {
    padding-top: 0;
    padding-bottom: 0;
}

.attribute.value {
    display: table-cell;
    padding-top: 6px;
    padding-bottom: 3px;
    padding-right: 4px;
    font-size: 16px;
    color: #2A2A2A;
    width: auto;
    display: inline-block;
    white-space: pre-line;
}

.attribute.value.notransform {
    text-transform: none;
}

.attribute.value.empty {
    padding-top: 0;
    padding-bottom: 0;
}

.attribute.value.a {
    color: #2A2A2A;
    text-decoration: none;
    text-transform: none;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 700px;
    white-space: nowrap;
}

.attribute.body {
    display: table-row;
    line-height: 1.5;
}

.attribute.label.withvalue {
    text-indent: 20px;
    padding-top: 6px;
    vertical-align: top;
}

.badge {
    display: inline-block;
    min-width: 30px;
    height: 22px;
    line-height: 22px;
    padding: 0 3px;
    text-align: center;
    background: #F4F4F4;
    border-radius: 11px;
    font-size: 14px;
    font-weight: 540;
    color: #26AAAA;
    margin: 0 5px;
    cursor: pointer;
}

.badge-no-margin {
    margin: 0;
}

.flag-icon {
    display: inline-block;
    width: 16px;
    height: 12px;
    margin-right: 3px;
}

.cell {
    display: table-cell;
}

.row {
    display: table-row;
}

.table {
    display: table;
    width: 100%;
}

.subitem-wrapper {
    display: table;
    width: 100%;
}

.subitem {
    display: table-row;
}

.subitem.label {
    display: table-cell;
}

.subitem.value {
    display: table-cell;
}

.sub-label {
    text-indent: 20px;
}

.value {
    display: inline-flex;
    color: #2A2A2A;
    font-size: 13px;
    align-items: center;
    justify-content: flex-start;
    text-transform: capitalize;
}

.value.normalized {
    text-transform: none;
}

.bar {
    display: inline-flex;
    width: 73px;
    height: 8px;
    border-radius: 4px;
    background: #D4D4D4;
    overflow: hidden;
    align-items: center;
    justify-content: flex-start;
    margin-right: 10px;
}

.bar-fill {
    display: inline-flex;
    background: #26AAAA;
    height: 100%;
    border-radius: 4px;
    overflow: hidden;
}