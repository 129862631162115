.p-login {
    min-height: 90%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: calc(40%);
    margin-top: calc(20%);
    background-color: hsl(0, 0%, 96%);
}

.p-login .c-form {
    position: absolute;
}

.p-login-logo {
    height: 20px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 10px;
    background-color: hsl(0, 0%, 96%);
}