
.r-pagination-wrapper {
    display: flex;
    margin: 0 -2px;
    font-family: "myriad-pro",sans-serif;
    float:right;
  }
  

.current-page {
    display: inline-block;
    min-width: 26px;
    height: 26px;
    border: 0;
    background: #26AAAA;
    color: #fff;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
    
}

.button-pagination {
    display: inline-block;
    min-width: 26px;
    height: 26px;
    border: 0;
    background: transparent;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    cursor: pointer;
    margin: 0 2px;
    font-family: "myriad-pro",sans-serif;
}

.button-pagination.btn-prev,
.button-pagination.btn-next {
      color: #26AAAA;
      font-weight: 600;
      
 }

 .button-pagination.disabled {
    color: #AAAAAA;
    font-weight: 400;
  }

 