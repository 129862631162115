.grids-display {
    flex: none;
    margin: 0 auto;
    justify-content: left;
    overflow-x: auto;
    overflow-y: hidden;
}

.c-table__sort-icon {
    display: block;
    width: 0.8rem;
    height: 1.3rem;
}

.c-table {
    border-spacing: 0px;
}


/* =========================================================================  */


/* Component: table cells */


/* =========================================================================  */

.c-table__head-data--sortable {
    cursor: pointer;
}

.c-table__sort {
    display: inline-flex;
    position: relative;
    top: 0.2rem;
    overflow: hidden;
}

.c-table__sort-icon {
    display: block;
    width: 0.9rem;
    height: 1.1rem;
    padding: 0px;
}

.is-asc .c-table__sort,
.is-desc .c-table__sort {
    height: 0.5rem;
    position: relative;
    top: -0.3rem;
}

.is-asc .c-table__sort-icon {
    position: relative;
    top: -0.8rem;
}

.c-table__head-data {
    padding: .6rem .8rem;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    white-space: nowrap;
    width: 100%;
    color: #4e5055;
    border-bottom: 1px solid #CBCBCB;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    white-space: nowrap;
}

.c-table__data {
    color: #4e5055;
    border-bottom: 1px solid #CBCBCB;
    padding: 1rem 2rem;
    font-weight: 400;
    font-size: 1.1rem;
    text-align: left;
    white-space: nowrap;
}

.c-table__smallfont {
    font-size: 16px;
    padding-right: 1rem;
}

.c-table__data.is-editable {
    position: relative;
}

.c-table__data.is-editable:before {
    content: '';
    display: block;
    width: calc(100% - 1.2rem);
    height: calc(100% - 1.2rem);
    position: absolute;
    left: 0.6rem;
    top: 0.6rem;
    background-color: #ffffff;
    border-radius: 0.2rem;
    z-index: -1;
}

.c-table__editable {
    position: relative;
    z-index: 1;
    cursor: text;
    outline: none;
}

.c-table__row.is-selected {
    background-color: #eff8fa;
    ;
}


/* =========================================================================  */


/* Modifier: popover buttons cells */


/* =========================================================================  */

.c-table__more-btn-column {
    width: 2.5rem;
}

.c-table__data--action {
    border-bottom: 0;
    padding: .9rem .8rem;
}

.c-table__more-btn {
    transition: opacity .08s ease-in-out;
    opacity: 0;
    vertical-align: middle;
}

.c-table__more-btn:hover,
.c-table__more-btn.has-popover-displayed {
    background-color: #f1f0f0;
}

.c-table__data--action .o-more-btn:focus {
    opacity: 1;
}

.c-table__row:hover .c-table__data--action .o-more-btn {
    opacity: 1;
}


/* =========================================================================  */


/* Component: Pagination */


/* =========================================================================  */

.c-pagination-wrapper {
    transition: box-shadow 0.133s ease-in;
    padding: 1.5rem 2.5rem;
    width: 100%;
}

.c-pagination-wrapper.is-overlapping {
    box-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.12);
}

.c-pagination {
    font-size: 1.4rem;
    font-weight: 500;
    height: 2.6rem;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.o-icon--caret-down {
    width: 0.9rem;
    height: 0.6rem;
}

.o-icon--caret-left {
    width: 0.6rem;
    height: 0.9rem;
}

.o-icon--caret-right {
    width: 0.6rem;
    height: 0.9rem;
    transform: rotate(180deg);
}


/* =========================================================================  */


/* State: is overlapping */


/* =========================================================================  */

.c-pagination-wrapper:before:after {
    content: "";
    left: 0;
    width: 100%;
    height: 0.6rem;
    position: absolute;
    z-index: 1;
    transition: opacity 0.133s ease-in;
    opacity: 0;
    top: -.6rem;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.06), rgba(255, 255, 255, 0));
}

.c-pagination-wrapper:before.is-overlapping:after {
    opacity: 1;
}