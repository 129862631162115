/**
   Tooltip Icon
*/

.tooltip-icon {
    position: relative;
    display: inline-block;
    width: 15px;
    height: 16px;
    background: transparent none no-repat 50% 50%;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='11' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5' cy='5' r='5' fill='%232A2A2A'/%3E%3Cpath d='M5.27306 6.352L5.26506 6.184C5.24906 5.8 5.37706 5.472 5.68906 5.104C6.03306 4.712 6.38506 4.312 6.38506 3.736C6.38506 3.096 5.92906 2.52 4.93706 2.52C4.43306 2.52 3.96906 2.664 3.68106 2.848L3.92106 3.552C4.12106 3.4 4.44106 3.312 4.72106 3.312C5.16906 3.32 5.37706 3.544 5.37706 3.88C5.37706 4.192 5.17706 4.472 4.87306 4.832C4.49706 5.288 4.35306 5.728 4.39306 6.144L4.40906 6.352H5.27306ZM4.81706 8.088C5.18506 8.088 5.42506 7.816 5.42506 7.464C5.41706 7.096 5.17706 6.84 4.81706 6.84C4.46506 6.84 4.20906 7.096 4.20906 7.464C4.20906 7.816 4.45706 8.088 4.81706 8.088Z' fill='white'/%3E%3C/svg%3E%0A");
    cursor: pointer;
}

.tooltip-icon.tooltip-text:hover {
    display: block;
}


/**
   Tooltip windows
*/

.normalizedTooltip {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #2A2A2A;
    border-radius: 4px;
    padding: 20px;
    color: #fff;
    max-width: 450px;
    width: 80%;
    z-index: 8100;
    display: block;
}

.normalizedTooltip.hidden {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #2A2A2A;
    border-radius: 4px;
    padding: 20px;
    color: #fff;
    max-width: 450px;
    width: 80%;
    z-index: 8100;
    display: none;
}

.normalized-tooltip-text-content {
    position: relative;
    font-size: 12px;
}

.normalized-tooltip-text-content.p {
    font-size: 13px;
    margin-bottom: 12px;
}

.normalized-tooltip-text-content.ul {
    font-size: 13px;
    margin-bottom: 12px;
    padding-left: 20px;
}

.normalized-tooltip-text-content.close {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #fff;
    border: 0;
    cursor: pointer;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    background: #fff none no-repeat 50% 50%;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.06095 4.99984L9.0297 2.03109C9.17059 1.89044 9.24984 1.69958 9.25002 1.5005C9.2502 1.30142 9.17128 1.11042 9.03063 0.969524C8.88999 0.828627 8.69913 0.749374 8.50005 0.749198C8.30096 0.749022 8.10997 0.827939 7.96907 0.968586L5.00032 3.93734L2.03157 0.968586C1.89068 0.82769 1.69958 0.748535 1.50032 0.748535C1.30107 0.748535 1.10997 0.82769 0.969074 0.968586C0.828178 1.10948 0.749023 1.30058 0.749023 1.49984C0.749023 1.69909 0.828178 1.89019 0.969074 2.03109L3.93782 4.99984L0.969074 7.96859C0.828178 8.10948 0.749023 8.30058 0.749023 8.49984C0.749023 8.69909 0.828178 8.89019 0.969074 9.03109C1.10997 9.17198 1.30107 9.25114 1.50032 9.25114C1.69958 9.25114 1.89068 9.17198 2.03157 9.03109L5.00032 6.06234L7.96907 9.03109C8.10997 9.17198 8.30106 9.25114 8.50032 9.25114C8.69958 9.25114 8.89067 9.17198 9.03157 9.03109C9.17247 8.89019 9.25162 8.69909 9.25162 8.49984C9.25162 8.30058 9.17247 8.10948 9.03157 7.96859L6.06095 4.99984Z' fill='%232A2A2A'/%3E%3C/svg%3E%0A");
}

.normalizedTooltipBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #2A2A2A;
    border-radius: 4px;
    padding: 10px;
    color: #fff;
    opacity: .4;
    display: block;
    z-index: 8000;
    display: block;
}

.normalizedTooltipBackdrop.hidden {
    display: none;
}