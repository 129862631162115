.c-tabs__mainnav {
    width: 100%;
}

.c-tabs__nav {
    width: 100%;
    box-shadow: inset 0 -2px 0 0 #E9E9E9;
}

.c-tabs__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.c-tabs__title {
    display: inline-block;
}

.c-tabs__link {
    border: 0;
    background-color: transparent;
    padding-top: 1.8rem;
    padding-bottom: 0.6rem;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    font-size: 1rem;
    color: #7f7f7f;
    position: relative;
}

[data-whatintent="mouse"] .c-tabs__link:focus {
    outline: none;
}

.c-tabs__link.is-active {
    color: #303030!important;
    padding-bottom: 14px;
}

.c-tabs__link.is-active:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 0.2rem;
    background-color: #ff2a4d;
    position: absolute;
    left: 0;
    bottom: 0;
}

.c-tabs__link.is-active:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    background-color: #ff2a4d;
    width: 100%;
    -webkit-transition: -webkit-transform .25s cubic-bezier(.165, .84, .44, 1);
    transition: -webkit-transform .25s cubic-bezier(.165, .84, .44, 1);
    transition: transform .25s cubic-bezier(.165, .84, .44, 1);
    transition: transform .25s cubic-bezier(.165, .84, .44, 1), -webkit-transform .25s cubic-bezier(.165, .84, .44, 1);
    -webkit-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scaleX(0);
    transform: scaleX(0)
}

.c-tabs__link:hover {
    color: #52545a;
}

[data-whatintent="keyboard"] .c-tabs__link:focus {
    color: #53565e;
}

.c-tabs__title:nth-child(n+2) .c-tabs__link {
    margin-left: 2rem;
}

.c-tabs__item {
    transition: opacity 0.2s ease-out;
}

.c-tabs__content {
    overflow-y: auto;
}

.c-tabs__item.is-animating {
    transition: height 0.2s ease-out;
    opacity: 0;
}

.js .c-tabs__item {
    display: none;
}

.js .c-tabs__item.is-active {
    display: block;
}