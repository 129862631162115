.c-popover {
    z-index: 1000;
    background: #ffffff;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.08);
    border-radius: 0.4rem;
    padding: 1rem 2rem;
    text-align: left;
    min-width: 20rem;
  }

  .c-popover-right-click {
    z-index: 1000;
    background: #ffffff;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.08);
    border-radius: 0.4rem;
    text-align: left;
    min-width: 20rem;
  }
  
  .c-popover-display {
    width: 25rem;
  }
  
  .c-popover.is-hidden {
    display: none;
  }
  
  .c-popover.is-ready {
    position: absolute;
    top: 0;
    left: 0;
  }

/* =========================================================================  */
/* Popover */
/* =========================================================================  */
.c-lc-popover {
    position: fixed;
    visibility: hidden;
    pointer-events: none;
    border-bottom: 1px solid rgb(212, 212, 212);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(212, 212, 212);
    padding: 20px 30px;
  }
  
  .c-lc-popover.is-visible {
    visibility: visible;
    pointer-events: auto;
  }

  /* =========================================================================  */
/* Modal */
/* =========================================================================  */
/* ======================================================= */
/* Component: Modal */
/* ======================================================= */
/**
 * 1. Required to hide the extra views.
 */
 .d-modal {
  width: 50rem;
  border-radius: 0.4rem;
  background-color: #ffffff;
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  z-index: 30;
  opacity: 1;
  transition: opacity 0.1s ease-out, height 0.2s ease-out, width 0.2s ease-out, -webkit-transform 0.1s ease-out;
  transition: opacity 0.1s ease-out, transform 0.1s ease-out, height 0.2s ease-out, width 0.2s ease-out;
  transition: opacity 0.1s ease-out, transform 0.1s ease-out, height 0.2s ease-out, width 0.2s ease-out, -webkit-transform 0.1s ease-out;
  overflow: hidden;
}

.d-modal-sub {
  width: 50rem;
  border-radius: 0.4rem;
  background-color: #ffffff;
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  z-index: 40;
  opacity: 1;
  transition: opacity 0.1s ease-out, height 0.2s ease-out, width 0.2s ease-out, -webkit-transform 0.1s ease-out;
  transition: opacity 0.1s ease-out, transform 0.1s ease-out, height 0.2s ease-out, width 0.2s ease-out;
  transition: opacity 0.1s ease-out, transform 0.1s ease-out, height 0.2s ease-out, width 0.2s ease-out, -webkit-transform 0.1s ease-out;
  overflow: hidden;
}

.d-modal.is-static {
  position: relative;
  left: auto;
  top: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.d-modal.is-hidden {
  -webkit-transform: translate(-50%, -50%) scale(0.98);
  -ms-transform: translate(-50%, -50%) scale(0.98);
  transform: translate(-50%, -50%) scale(0.98);
  transition-timing-function: ease-in;
  opacity: 0;
  pointer-events: none;
}

.d-modal.is-static.is-hidden {
  -webkit-transform: scale(0.98);
  -ms-transform: scale(0.98);
  transform: scale(0.98);
}

.d-modal.is-maximized {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  border-radius: 0;
}

.d-modal.is-transitioning {
  transition: none;
}

.d-modal__view {
  transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
  z-index:0;
}

.d-modal__view.is-hidden {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  -webkit-transform: translateX(3rem);
  -ms-transform: translateX(3rem);
  transform: translateX(3rem);
  pointer-events: none;
  opacity: 0;
}

.d-modal__view.is-pushed-out {
  -webkit-transform: translateX(-3rem);
  -ms-transform: translateX(-3rem);
  transform: translateX(-3rem);
  opacity: 0;
  pointer-events: none;
}

.d-modal__head,
.d-modal__foot {
  padding: 1rem;
  width: 100%;
}

.d-modal__back {
  position: relative;
  top: -0.1rem;
  margin-left: -0.8rem;
}

.d-modal__title {
    font-size: 20px;
    font-family: sans-serif;
    font-weight : 800;
    line-height: 1.05;
    margin: 0;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 20%;
  }
  
  .d-modal__close {
    top: -1.2rem;
    right: 1.2rem;
  }
  
  .d-modal__body {
    padding: 0 2rem;
    font-size: 1.2rem;
    line-height: 1.4;
  }
  
  .d-modal__body > p {
    color: #888D95;
  }
  
  .d-modal__body--scrollable {
    padding: 0;
  }
  
  .d-modal__head {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #575A61;
    width: 100%;
  }
  
  .d-modal__foot {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 2rem;
  }