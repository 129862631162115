.card {
    width: 90%;
    max-width: 930px;
    margin-bottom: 5px;
    margin-right: 5px;
    margin-left: 20px;
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.15);
    overflow: hidden;
}

.header {
    border-bottom: 1px solid #D4D4D4;
    margin-right: 5px;
    margin-left: 20px;
    border-radius: 8px;
    background: #FFFFFF;
    cursor: default;
    box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.15);
}

.header.main-name {
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
    color: #2A2A2A;
    margin-bottom: 7px;
    box-shadow: none;
    background-color: transparent;
    border-bottom: none;
}

.header.label-entity-type {
    display: inline-block;
    height: 24px;
    line-height: 24px;
    border-radius: 12px;
    background: #E4E4E4;
    padding: 0 10px;
    margin-right: 5px;
    font-size: 12px;
    color: #2A2A2A;
    text-transform: capitalize;
    border-bottom: 0px;
    box-shadow: none;
}

.header.label-classification,
.header.label-dataset {
    display: inline-block;
    height: 24px;
    line-height: 24px;
    border-radius: 4px;
    background: #FFEAED;
    padding: 0 10px;
    margin-right: 5px;
    font-size: 12px;
    color: #2A2A2A;
    border-bottom: 0px;
    box-shadow: none;
}