/* =========================================================================  */
/* Component: progress loader */
/* =========================================================================  */
.d-loader {
    transition: opacity .08s linear;
    content: "";
    height: .3rem;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #0D0DC7;
    opacity: 0;
    z-index: 9999;
    -webkit-transform-origin: left top;
    -ms-transform-origin: left top;
    transform-origin: left top;
  }
  
  /* =========================================================================  */
  /* Interaction: loading state */
  /* =========================================================================  */
  .d-loader.is-loading {
    opacity: 1;
    -webkit-animation: fakeProgress .2s ease-in-out forwards;
    animation: fakeProgress .2s ease-in-out forwards;
  }
  
  .d-loader--infinite.is-loading {
    -webkit-animation: infiniteLoader 1.4s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
    animation: infiniteLoader 1.4s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
  }
  
  @-webkit-keyframes infiniteLoader {
    0% {
      -webkit-transform: scale(0, 1);
      transform: scale(0, 1);
    }
    49.999% {
      -webkit-transform-origin: left top;
      transform-origin: left top;
    }
    50% {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
      -webkit-transform-origin: right top;
      transform-origin: right top;
    }
    100% {
      -webkit-transform: scale(0, 1);
      transform: scale(0, 1);
      -webkit-transform-origin: right top;
      transform-origin: right top;
    }
  }
  
  @keyframes infiniteLoader {
    0% {
      -webkit-transform: scale(0, 1);
      transform: scale(0, 1);
    }
    49.999% {
      -webkit-transform-origin: left top;
      transform-origin: left top;
    }
    50% {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
      -webkit-transform-origin: right top;
      transform-origin: right top;
    }
    100% {
      -webkit-transform: scale(0, 1);
      transform: scale(0, 1);
      -webkit-transform-origin: right top;
      transform-origin: right top;
    }
  }
  
  @-webkit-keyframes fakeProgress {
    0% {
      width: 0%;
    }
    50% {
      width: 30%;
    }
    70% {
      width: 50%;
    }
    100% {
      width: 100%;
    }
  }
  
  @keyframes fakeProgress {
    0% {
      width: 0%;
    }
    50% {
      width: 30%;
    }
    70% {
      width: 50%;
    }
    100% {
      width: 100%;
    }
  }