/* =========================================================================  */


/* Object: button */


/* =========================================================================  */

.icon {
    vertical-align: middle;
    width: 20px;
    height: 20px
}

.icon.user {
    -webkit-transition: fill .25s cubic-bezier(.25, .46, .45, .94), opacity .25s cubic-bezier(.25, .46, .45, .94);
    transition: fill .25s cubic-bezier(.25, .46, .45, .94), opacity .25s cubic-bezier(.25, .46, .45, .94);
    fill: #303030
}

.o-div-btn {
    padding-top: 10px;
    width: 100%;
    align-items: center;
    align-content: center;
}

.m-button {
    background-color: #23b4b4;
    color: #fff;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    -webkit-transition: background-color .25s cubic-bezier(.25, .46, .45, .94);
    transition: background-color .25s cubic-bezier(.25, .46, .45, .94);
    position: relative;
    vertical-align: top;
    border: 0;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
    height: 2.4rem;
    width: 100%;
    letter-spacing: 0px;
    font-family: "myriad-pro", sans-serif;
    font-weight: 800;
    font-size: 16px;
    line-height: 1.2;
}

.m-button.disabled {
    background-color: #8ea5a5;
    cursor: unset;
}

.m-button:after,
.m-button:before {
    display: none
}

.m-button+.hover-text {
    color: #fff;
    font-style: italic;
    margin: 10px 0 0
}

@media (min-width:61.25em) {
    .m-button+.hover-text {
        margin: 20px 0 0;
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        -webkit-transition: opacity .3s cubic-bezier(.25, .46, .45, .94), -webkit-transform .3s cubic-bezier(.25, .46, .45, .94);
        transition: opacity .3s cubic-bezier(.25, .46, .45, .94), -webkit-transform .3s cubic-bezier(.25, .46, .45, .94);
        transition: opacity .3s cubic-bezier(.25, .46, .45, .94), transform .3s cubic-bezier(.25, .46, .45, .94);
        transition: opacity .3s cubic-bezier(.25, .46, .45, .94), transform .3s cubic-bezier(.25, .46, .45, .94), -webkit-transform .3s cubic-bezier(.25, .46, .45, .94)
    }
}

.m-button-rotate {
    transform: rotate(-90deg);
}

.m-button--small-margin-bottom {
    margin-bottom: 0.5rem;
}

[data-whatintent="keyboard"] .m-button:focus {
    outline: none;
    border-radius: 0.2rem;
    box-shadow: 0 0 0 0.3rem #99c8f8;
}

[data-whatintent="mouse"] .m-button:focus {
    outline: none;
}

.m-button:hover {
    color: #fff;
    background-color: rgba(35, 180, 180, .7);
}

.m-button.has-popover-displayed,
.m-button.is-active {
    color: #0D0DC7 !important;
    background-color: #DDDDF8 !important;
}

.m-button[disabled],
.o-select[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}


/* =========================================================================  */


/* Modifier: neutral */


/* =========================================================================  */

.m-button--neutral {
    color: #888D95;
    background-color: #E9E9E9;
}

.m-button--neutral:hover {
    color: #575A61;
    background-color: #d5d5d5;
}

.m-button--neutral-light {
    color: #888D95;
    background-color: #F6F6F6;
}

.m-button--neutral-light:hover {
    color: #575A61;
    background-color: #F6F6F6;
}


/* =========================================================================  */


/* Modifier: Inline */


/* =========================================================================  */

.m-button--inline {
    color: #888D95;
    background: none;
    padding: 0;
}

.m-button--inline:hover {
    color: #575A61;
    background: none;
}

.m-button--inline.strong {
    font-weight: bolder;
}


/* =========================================================================  */


/* Modifier: prepended */


/* =========================================================================  */

.o-icon--prepended {
    display: inline-block;
    margin-right: 0.2rem;
    position: relative;
    top: -0.2rem;
}

.o-icon--close {
    width: 20px;
    height: 20px;
}


/* =========================================================================  */


/* Button close */


/* =========================================================================  */

.m-button--close {
    padding: 0;
    width: 1.1rem;
    height: 1.1rem;
    margin-top: 0.6rem;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #888D95;
    background-color: transparent;
}

.m-button--close:focus,
.m-button--close:hover {
    background-color: #F6F6F6;
    color: #575A61;
}


/* ========================================================================= */

.btn-file {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}