/*
   Main Search
*/

.searchWrapper {
    font-family: "myriad-pro", sans-serif;
    font-size: 16px;
    color: #2A2A2A;
    min-height: calc(100vh - 170px);
    width: 100%;
}

.searchWrapper::after,
.searchWrapper::before {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-family: "jaf-bernina-sans", sans-serif;
    font-weight: 600;
}

.searchWrapper.button {
    font-family: "myriad-pro", sans-serif;
}

.searchpageContent {
    position: relative;
    max-width: 1260px;
    margin: -150px auto 0;
    padding: 0 20px 30px;
}


/**
    Search FORM

*/

.search-form {
    position: relative;
    background: #26AAAA none no-repeat 50% 50%;
    min-height: 277px;
    max-height: 277px;
    width: 100%;
    background-image: url('../svg/hero.svg');
    background-size: cover;
    transition: all .8s ease-in-out;
    flex-direction: column;
    padding-top: 10px;
}

.search.visual-no-search {
    width: 100%;
    display: block;
    opacity: 0;
    pointer-events: none;
    transition: all .3s ease-in-out;
    max-height: 0;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 620px;
    padding: 0 20px;
}

.search-img {
    width: 100%;
    margin: 0 auto;
    display: block;
}

.search.no-search {
    margin-bottom: 350px;
}

.search-img.visual-no-search {
    max-height: 500px;
    margin-top: -120px;
    max-width: 620px;
    width: 100%;
    padding: 0 20px;
    opacity: 1;
}

.search-img.visual-no-search.img {
    width: 100%;
    margin: 0 auto;
    display: block;
}

.search-content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    min-height: 121px;
    max-width: 620px;
    height: 26.67%;
    z-index: 20;
    text-align: center;
    padding: 35px 20px 150px;
    padding-top: 0px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    transition: all .3s ease-in-out;
}

.search-content.h1 {
    font-size: 20px;
    color: #fff;
    margin-bottom: 20px;
}

.search-content.form-baseline {
    font-size: 20px;
    color: #fff;
    margin-bottom: 20px;
    margin-top: 20px;
    overflow: hidden;
    transition: all .3s ease-in-out;
    font-weight: 700;
}

.search-content.form-baseline.show {
    opacity: 1;
    max-height: 100px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.search-content.form-baseline.hide {
    opacity: 0;
    max-height: 0;
    margin-bottom: 0;
    margin-top: 0;
}

.search-FormBar {
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    box-sizing: content-box;
}

.search-Label {
    display: none;
}

.search-date {
    position: relative;
    overflow: visible;
    box-sizing: content-box;
}

.search-Field {
    width: calc(100% - 100px);
    padding: 0 44px 0 56px;
    flex: 1;
    height: 64px;
    border-radius: 8px;
    overflow: hidden;
    border: 0;
    background: #fff none no-repeat 20px 50%;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z' stroke='%2326AAAA' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21.0004 20.9999L16.6504 16.6499' stroke='%2326AAAA' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    font-family: "myriad-pro", sans-serif;
    font-size: 18px;
}

.search-Field.error {
    background-color: rgba(255, 42, 77, 0.05);
    border-color: rgba(255, 42, 77, 0.3);
}

.search-Field:focus {
    border: 0;
    outline: none;
}

.search-SubmitButton {
    display: none;
}

.search-ClearButton {
    position: absolute;
    top: 22px;
    right: 22px;
    width: 20px;
    height: 20px;
    display: block;
    background: #D4D4D4 none no-repeat 50% 50%;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.06095 4.99984L9.0297 2.03109C9.17059 1.89044 9.24984 1.69958 9.25002 1.5005C9.2502 1.30142 9.17128 1.11042 9.03063 0.969524C8.88999 0.828627 8.69913 0.749374 8.50005 0.749198C8.30096 0.749022 8.10997 0.827939 7.96907 0.968586L5.00032 3.93734L2.03157 0.968586C1.89068 0.82769 1.69958 0.748535 1.50032 0.748535C1.30107 0.748535 1.10997 0.82769 0.969074 0.968586C0.828178 1.10948 0.749023 1.30058 0.749023 1.49984C0.749023 1.69909 0.828178 1.89019 0.969074 2.03109L3.93782 4.99984L0.969074 7.96859C0.828178 8.10948 0.749023 8.30058 0.749023 8.49984C0.749023 8.69909 0.828178 8.89019 0.969074 9.03109C1.10997 9.17198 1.30107 9.25114 1.50032 9.25114C1.69958 9.25114 1.89068 9.17198 2.03157 9.03109L5.00032 6.06234L7.96907 9.03109C8.10997 9.17198 8.30106 9.25114 8.50032 9.25114C8.69958 9.25114 8.89067 9.17198 9.03157 9.03109C9.17247 8.89019 9.25162 8.69909 9.25162 8.49984C9.25162 8.30058 9.17247 8.10948 9.03157 7.96859L6.06095 4.99984Z' fill='%237F7F7F'/%3E%3C/svg%3E%0A");
    border: 0;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease-in-out;
    box-sizing: border-box;
    padding: 0;
}

.search-ClearButton.hide {
    opacity: 0;
    pointer-events: none;
}

.search-ClearButton.show {
    opacity: 1;
    pointer-events: all;
}

.search-DateButton {
    position: absolute;
    top: 16px;
    right: 8px;
    width: 20px;
    height: 20px;
    display: block;
    background: #D4D4D4 none no-repeat 50% 50%;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.06095 4.99984L9.0297 2.03109C9.17059 1.89044 9.24984 1.69958 9.25002 1.5005C9.2502 1.30142 9.17128 1.11042 9.03063 0.969524C8.88999 0.828627 8.69913 0.749374 8.50005 0.749198C8.30096 0.749022 8.10997 0.827939 7.96907 0.968586L5.00032 3.93734L2.03157 0.968586C1.89068 0.82769 1.69958 0.748535 1.50032 0.748535C1.30107 0.748535 1.10997 0.82769 0.969074 0.968586C0.828178 1.10948 0.749023 1.30058 0.749023 1.49984C0.749023 1.69909 0.828178 1.89019 0.969074 2.03109L3.93782 4.99984L0.969074 7.96859C0.828178 8.10948 0.749023 8.30058 0.749023 8.49984C0.749023 8.69909 0.828178 8.89019 0.969074 9.03109C1.10997 9.17198 1.30107 9.25114 1.50032 9.25114C1.69958 9.25114 1.89068 9.17198 2.03157 9.03109L5.00032 6.06234L7.96907 9.03109C8.10997 9.17198 8.30106 9.25114 8.50032 9.25114C8.69958 9.25114 8.89067 9.17198 9.03157 9.03109C9.17247 8.89019 9.25162 8.69909 9.25162 8.49984C9.25162 8.30058 9.17247 8.10948 9.03157 7.96859L6.06095 4.99984Z' fill='%237F7F7F'/%3E%3C/svg%3E%0A");
    border: 0;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease-in-out;
    box-sizing: border-box;
    padding: 0;
}

.search-DateButton.hide {
    opacity: 0;
    pointer-events: none;
}

.search-DateButton.show {
    opacity: 1;
    pointer-events: all;
}


/*
   Filter
*/

.countrySelect {
    width: 400px;
    padding-top: 7px;
    z-index: 1000;
}

.countrySelectField {
    width: 300px;
    padding-top: 7px;
    padding-left: 10px;
    z-index: 1004;
}

.dateSelectField {
    width: 300px;
    padding-top: 7px;
    padding-left: 10px;
    z-index: 999;
}

.countryAlgoSelect {
    width: 200px;
    padding-left: 10px;
    padding-top: 7px;
    z-index: 1000;
}

.dateAlgoSelect {
    width: 200px;
    padding-left: 10px;
    padding-top: 7px;
    z-index: 900;
}

.filterWrapper {
    position: relative;
    z-index: 20;
    background: #fff none;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 0;
    margin-bottom: 40px;
    transition: all .4s ease-in-out;
    max-height: 1600px;
    opacity: 1;
}

.filterWrapper.no-search {
    max-height: 0 !important;
    min-height: 0 !important;
    overflow: hidden;
    opacity: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.filterWrapper.label {
    font-size: 14px;
    font-weight: 600;
    box-sizing: inherit;
    background: none;
    box-shadow: none;
    border-radius: 0px;
}

.filterWrapper.label::before,
.filterWrapper.label::after {
    box-sizing: inherit;
}

.filterWrapper.categories-toggle-select {
    display: inline-block;
    appearance: none;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 1.75L5 5.25L8.5 1.75' stroke='%2326AAAA' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: calc(100% - 5px) 50%;
    background-repeat: no-repeat;
    border: 1px solid #AAAAAA;
    border-radius: 4px;
    padding: 0 25px 0 10px;
    width: 100%;
    margin: 10px 0 0;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    outline: none;
    line-height: 27px;
    color: #2A2A2A;
}

.filterWrapper.categories {
    padding: 0;
    margin-bottom: 0;
    transition: unset;
}

.filterWrapper.categories.ul {
    box-sizing: inherit;
    margin: 0 0 25px;
    background: none;
    box-shadow: none;
    border-radius: 0%;
    position: relative;
    transition: none;
    max-height: none;
    opacity: 1;
    padding-top: 5px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid #d4d4d4;
}

.filterWrapper.categories.li {
    display: inline-block;
    margin-right: 35px;
    cursor: pointer;
    color: #7F7F7F;
    box-sizing: inherit;
    background: none;
    box-shadow: none;
    border-radius: 0px;
    transition: unset;
}

.filterWrapper.categories.span {
    display: inline-block;
    padding: 5px 0;
    font-size: 28px;
    font-weight: 700;
    box-sizing: inherit;
    background: none;
    box-shadow: none;
    border-radius: 0px;
}

.filterWrapper.categories.em {
    display: inline-block;
    width: 35px;
    font-size: 12px;
    font-style: normal;
    padding-left: 5px;
}

.filterWrapper.categories.li.active {
    color: #2A2A2A;
}

.filterWrapper.categories.span {
    padding-bottom: 3px;
}

.filterWrapper.categories.span.active {
    border-bottom: 2px solid #FF2A4D;
    padding-bottom: 3px;
}

.filterWrapper.categories.disable {
    color: #E4E4E4;
    cursor: default;
}

.filterWrapper.categories.reset {
    display: inline-flex;
    align-items: center;
    background: transparent;
    border: 0;
    color: #26AAAA;
    font-size: 14px;
    cursor: pointer;
    font-family: "myriad-pro", sans-serif;
    font-weight: 600;
    box-sizing: inherit;
    box-shadow: none;
    border-radius: 0px;
}

.filterWrapper.categories.reset-desktop {
    display: none !important;
}

.filterWrapper.categories.reset-mobile {
    display: none;
}

.filterWrapper.categories.toggle {
    display: inline-flex;
    align-items: center;
    background: transparent;
    border: 0;
    color: #26AAAA;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    font-family: "myriad-pro", sans-serif;
    box-sizing: inherit;
    box-shadow: none;
    border-radius: 0px;
}

.filterWrapper.categories.toggle::after {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    background: transparent none no-repeat 50% 50%;
    background-size: contain;
    margin-left: 5px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 5.25V18.75' stroke='%2326AAAA' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M18.75 12H5.25' stroke='%2326AAAA' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.filterWrapper.categories.toggle-close {
    display: inline-flex;
    align-items: center;
    background: transparent;
    border: 0;
    color: #26AAAA;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    font-family: "myriad-pro", sans-serif;
    box-sizing: inherit;
    box-shadow: none;
    border-radius: 0px;
}

.filterWrapper.categories.toggle-close::after {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    background: transparent none no-repeat 50% 50%;
    background-size: contain;
    margin-left: 5px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='3' viewBox='0 0 15 3' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.5137 1.2373H1.31445' stroke='%2326AAAA' stroke-width='1.80729' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.filterWrapper.categories.toggle-desktop {
    display: none;
}

.filterWrapper.categories.toggle-mobile {
    width: 100%;
    justify-content: center;
    margin-top: 15px;
}

.filterMain {
    padding: 0px;
    border-bottom: 0px;
}

.filterMain.label {
    font-size: 14px;
    font-weight: 600;
    border-bottom: 0;
    padding: 0;
}

.filterMain.label::before,
.filterMain.label::after {
    box-sizing: inherit;
}

.filterMain.title-bar {
    display: flex;
    justify-content: space-between;
    -webkit-box-pack: justify;
    border-bottom: 0;
    padding-top: 20px;
    padding-left: 20px;
    font-size: 14px;
    font-weight: 600;
}

.filterMain.title-bar::before,
.filterMain.title-bar::after {
    box-sizing: inherit;
}

.filterAdvanced {
    padding-top: 0px;
    padding-left: 20px;
    padding-bottom: 10px;
}

.filterAdvanced.open {
    padding-top: 0px;
    padding-left: 20px;
    padding-bottom: 0px;
}

.filterAdvanced.buttons-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filterAdvanced.list-entities {
    list-style-type: none;
    padding: 0;
    display: flex;
    margin: 3px 5px 0;
}

.filterAdvanced.list-entities.li {
    padding: 0px 0px 0px 0;
}

.filterAdvanced.list-entities.li.selected {
    display: none;
}

.filterAdvanced.list-entities.button {
    border: 0;
    border-radius: 14px;
    background: #E4E4E4;
    color: #2A2A2A;
    font-size: 14px;
    line-height: 28px;
    padding: 0 10px;
    cursor: pointer;
    font-family: "myriad-pro", sans-serif;
}

.filterAdvanced.list-entities.button.disabled {
    border: 0;
    border-radius: 14px;
    background: #E4E4E4;
    font-size: 14px;
    line-height: 28px;
    padding: 0 10px;
    font-family: "myriad-pro", sans-serif;
    background-color: #F4F4F4;
    color: #D4D4D4;
    cursor: default;
}

.filterAdvanced.list-datasets {
    list-style-type: none;
    padding: 0;
    display: flex;
    margin: 5px 5px 0;
    flex-wrap: wrap;
}

.filterAdvanced.list-datasets.li {
    padding: 5px 0px 5px 0;
}

.filterAdvanced.list-datasets.li.selected {
    display: none;
    padding: 5px 8px 5px 8px;
}

.filterAdvanced.list-datasets.button {
    border: 0;
    border-radius: 4px;
    background: #FFEAED;
    color: #2A2A2A;
    font-size: 14px;
    line-height: 27px;
    padding: 0 10px;
    cursor: pointer;
}

.filterAdvanced.list-datasets.button.disabled {
    border: 0;
    border-radius: 4px;
    background: #FFEAED;
    font-size: 14px;
    line-height: 27px;
    padding: 0 10px;
    background-color: #F4F4F4;
    color: #D4D4D4;
    cursor: default;
}

.filterAdvancedDetails {
    overflow: hidden;
    transition: all .4s ease-in-out;
    padding-left: 20px;
}

.filterAdvancedSelection {
    display: flex;
    flex-wrap: wrap;
}

.filterAdvancedSelection.title {
    width: 100%;
    margin-bottom: 0;
    margin-top: 15px;
    font-size: 24px;
}

.filterAdvancedSelection.list-entities {
    display: inline-flex;
    padding-left: 0px;
}

.filterAdvancedSelection.list-entities.li {
    padding: 5px 20px 5px 0px;
    margin-bottom: 0px;
}

.filterAdvancedSelection.list-entities.button {
    background: #555555;
    color: #fff;
    text-transform: capitalize;
    border: 0px;
    border-radius: 14px;
    font-size: 14px;
    line-height: 28px;
    padding: 0px 10px;
    cursor: pointer;
    font-family: myriad-pro, sans-serif;
    margin: 0;
    box-sizing: inherit;
}

.filterAdvancedSelection.list-entities.button::after {
    content: '';
    display: inline-block;
    background: transparent none no-repeat 50% 50%;
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='9' viewBox='0 0 8 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.92821 4.50016L7.52586 1.90251C7.64915 1.77944 7.71849 1.61244 7.71865 1.43824C7.7188 1.26405 7.64975 1.09692 7.52668 0.973638C7.40362 0.850354 7.23661 0.781007 7.06242 0.780854C6.88822 0.7807 6.7211 0.849751 6.59782 0.972818L4.00016 3.57047L1.40251 0.972818C1.27922 0.849534 1.11201 0.780273 0.937662 0.780273C0.763311 0.780273 0.596102 0.849534 0.472818 0.972818C0.349534 1.0961 0.280273 1.26331 0.280273 1.43766C0.280273 1.61201 0.349534 1.77922 0.472818 1.90251L3.07047 4.50016L0.472818 7.09782C0.349534 7.2211 0.280273 7.38831 0.280273 7.56266C0.280273 7.73701 0.349534 7.90422 0.472818 8.02751C0.596102 8.15079 0.763311 8.22005 0.937662 8.22005C1.11201 8.22005 1.27922 8.15079 1.40251 8.02751L4.00016 5.42985L6.59782 8.02751C6.7211 8.15079 6.88831 8.22005 7.06266 8.22005C7.23701 8.22005 7.40422 8.15079 7.5275 8.02751C7.65079 7.90422 7.72005 7.73701 7.72005 7.56266C7.72005 7.38831 7.65079 7.2211 7.5275 7.09782L4.92821 4.50016Z' fill='white'/%3E%3C/svg%3E%0A");
    width: 8px;
    height: 8px;
    margin: 10px 0 0 10px;
    box-sizing: inherit;
}

.filterAdvancedSelection.list-datasets {
    display: inline-flex;
    align-items: center;
    padding: 0;
}

.filterAdvancedSelection.list-datasets.li,
.filterAdvancedSelection.list-datasets.button {
    background: #FF2A4D;
    color: #fff;
    padding-bottom: 0;
    border: 0px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 27px;
    padding-right: 3px;
    padding-left: 4px;
    cursor: pointer;
    font-family: myriad-pro, sans-serif;
    margin: 0;
    box-sizing: inherit;
}

.filterAdvancedSelection.list-datasets.button::after {
    content: '';
    display: inline-block;
    background: transparent none no-repeat 50% 50%;
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='9' viewBox='0 0 8 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.92821 4.50016L7.52586 1.90251C7.64915 1.77944 7.71849 1.61244 7.71865 1.43824C7.7188 1.26405 7.64975 1.09692 7.52668 0.973638C7.40362 0.850354 7.23661 0.781007 7.06242 0.780854C6.88822 0.7807 6.7211 0.849751 6.59782 0.972818L4.00016 3.57047L1.40251 0.972818C1.27922 0.849534 1.11201 0.780273 0.937662 0.780273C0.763311 0.780273 0.596102 0.849534 0.472818 0.972818C0.349534 1.0961 0.280273 1.26331 0.280273 1.43766C0.280273 1.61201 0.349534 1.77922 0.472818 1.90251L3.07047 4.50016L0.472818 7.09782C0.349534 7.2211 0.280273 7.38831 0.280273 7.56266C0.280273 7.73701 0.349534 7.90422 0.472818 8.02751C0.596102 8.15079 0.763311 8.22005 0.937662 8.22005C1.11201 8.22005 1.27922 8.15079 1.40251 8.02751L4.00016 5.42985L6.59782 8.02751C6.7211 8.15079 6.88831 8.22005 7.06266 8.22005C7.23701 8.22005 7.40422 8.15079 7.5275 8.02751C7.65079 7.90422 7.72005 7.73701 7.72005 7.56266C7.72005 7.38831 7.65079 7.2211 7.5275 7.09782L4.92821 4.50016Z' fill='white'/%3E%3C/svg%3E%0A");
    width: 8px;
    height: 8px;
    margin: 0 0 0 10px;
}


/*

Results

*/

.pagination.select {
    display: inline-block;
    appearance: none;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 1.75L5 5.25L8.5 1.75' stroke='%2326AAAA' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: calc(100% - 5px) 50%;
    background-repeat: no-repeat;
    border: 1px solid rgb(170, 170, 170);
    border-radius: 4px;
    padding: 0px 25px 0px 10px;
    margin: 0px 5px;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    outline: none;
    line-height: 27px;
}

.resultWrapper {
    transition: all .4s ease-in-out;
    opacity: 1;
}

.resultWrapper.no-search {
    opacity: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    max-height: 0;
}

.resultWrapper.main-title {
    font-size: 20px;
    margin-bottom: 10px;
}

.resultWrapper.main-title.no-result {
    font-size: 36px;
}

.resultWrapper.visual-no-search {
    max-height: 500px;
    max-width: 620px;
    width: 100%;
    padding: 0;
    opacity: 1;
    margin: 0 auto 0;
}

.resultWrapper.visual-no-search.img {
    width: calc(100% - 40px);
    margin: 0 auto;
    display: block;
}

.resultWrapper.mobile-topbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
}


/*
  Result list
*/

.resultList.ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.resultList.li {
    padding: 0;
    margin: 0;
}


/*
   Card
*/

.card {
    width: 100%;
    max-width: 940px;
    margin-bottom: 20px;
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.15);
    overflow: hidden;
}

.card.header {
    padding: 20px 30px;
    border-bottom: 1px solid #D4D4D4;
    width: 100%;
    max-width: none;
    background: none;
    box-shadow: unset;
    overflow: hidden;
    border-radius: unset;
    margin: unset;
}

.card.header::before,
.card.header::after {
    box-sizing: inherit;
}

.card.header.main-name {
    font-size: 24px;
    font-weight: bold;
    color: #2A2A2A;
    text-transform: capitalize;
    padding: 0;
    border-bottom: none;
    line-height: 1.05;
    margin: 0 0 7px;
}

.card-header-labels {
    text-transform: capitalize;
}

.card-header-label-entity-type {
    display: inline-block;
    height: 24px;
    line-height: 24px;
    border-radius: 12px;
    background: #E4E4E4;
    padding: 0 10px;
    margin-right: 5px;
    font-size: 12px;
    color: #2A2A2A;
    text-transform: capitalize;
}

.card-header-label-classification,
.card-header-label-dataset {
    display: inline-block;
    height: 24px;
    line-height: 24px;
    border-radius: 4px;
    background: #FFEAED;
    padding: 0 10px;
    margin-right: 5px;
    font-size: 12px;
    color: #2A2A2A;
}

.card.body {
    padding: 20px 15px;
    width: unset;
    max-width: unset;
    background: unset;
    box-shadow: unset;
    overflow: hidden;
    border-radius: unset;
    margin: unset;
}

.card-body-btn-view-more-wrapper {
    text-align: right;
    padding-top: 12px;
}

.card-body-btn-view-more {
    padding: 0;
    background: transparent;
    border: 0;
    color: #26AAAA;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    font-family: "myriad-pro", sans-serif;
}

.card.body.country-name {
    text-transform: capitalize;
}

.card.footer {
    width: unset;
    max-width: none;
    margin: unset;
    border-radius: unset;
    background: none;
    box-shadow: none;
    overflow: hidden;
}

.card.footer.footerTop {
    padding: 20px 30px;
    background: transparent none no-repeat 100% 50%;
    background-color: #F4F4F4;
    color: #555;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    background-position: 97%;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 5.25V18.75' stroke='%2326AAAA' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M18.75 12H5.25' stroke='%2326AAAA' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.card.footer.footerTop.opened {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='3' viewBox='0 0 15 3' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.5137 1.2373H1.31445' stroke='%2326AAAA' stroke-width='1.80729' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.card.footer.footerDetail {
    padding: 10px 30px 20px 30px;
    overflow: hidden;
    transition: all .4s ease-in-out;
    background-color: #F4F4F4;
}

.card.footer.footerDetail.ul {
    list-style-type: none;
    padding: 0;
    box-sizing: inherit;
    margin-right: unset;
}

.card.footer.footerDetail.li {
    padding: 5px 0;
    box-sizing: inherit;
    margin: unset;
}

.card.footer.footerDetail.label {
    display: inline-flex;
    min-width: 150px;
    color: #AAAAAA;
    font-size: 13px;
    align-items: center;
    justify-content: flex-start;
    text-transform: capitalize;
    padding: unset;
    box-sizing: inherit;
    width: auto;
}

.card.footer.footerDetail.value {
    display: inline-flex;
    color: #2A2A2A;
    font-size: 13px;
    align-items: center;
    justify-content: flex-start;
    text-transform: capitalize;
    padding: unset;
    width: auto;
}

.card.footer.footerDetail.value.normalized {
    text-transform: unset;
}

.card.footer.footerDetail.value.bar {
    display: inline-flex;
    width: 73px;
    height: 8px;
    border-radius: 4px;
    background: #D4D4D4;
    overflow: hidden;
    align-items: center;
    justify-content: flex-start;
    margin-right: 10px;
}

.card.footer.footerDetail.value.bar-fill {
    display: inline-flex;
    background: #26AAAA;
    height: 100%;
    border-radius: 4px;
    overflow: hidden;
}

.card.footer.footerDetail.separator {
    display: inline-block;
    width: 19px;
    height: 19px;
    background: transparent none no-repat 50% 50%;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.3536 12.8536C16.5488 12.6583 16.5488 12.3417 16.3536 12.1464L13.1716 8.96447C12.9763 8.7692 12.6597 8.7692 12.4645 8.96447C12.2692 9.15973 12.2692 9.47631 12.4645 9.67157L15.2929 12.5L12.4645 15.3284C12.2692 15.5237 12.2692 15.8403 12.4645 16.0355C12.6597 16.2308 12.9763 16.2308 13.1716 16.0355L16.3536 12.8536ZM4 13L16 13L16 12L4 12L4 13Z' fill='%232A2A2A'/%3E%3Cpath d='M2.64645 6.64645C2.45118 6.84171 2.45118 7.15829 2.64645 7.35355L5.82843 10.5355C6.02369 10.7308 6.34027 10.7308 6.53553 10.5355C6.7308 10.3403 6.7308 10.0237 6.53553 9.82843L3.70711 7L6.53553 4.17157C6.7308 3.97631 6.7308 3.65973 6.53553 3.46447C6.34027 3.2692 6.02369 3.2692 5.82843 3.46447L2.64645 6.64645ZM15 6.5L3 6.5L3 7.5L15 7.5L15 6.5Z' fill='%232A2A2A'/%3E%3C/svg%3E%0A");
}

.card.footer.footerDetail.separator-clear {
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.3536 12.8536C16.5488 12.6583 16.5488 12.3417 16.3536 12.1464L13.1716 8.96447C12.9763 8.7692 12.6597 8.7692 12.4645 8.96447C12.2692 9.15973 12.2692 9.47631 12.4645 9.67157L15.2929 12.5L12.4645 15.3284C12.2692 15.5237 12.2692 15.8403 12.4645 16.0355C12.6597 16.2308 12.9763 16.2308 13.1716 16.0355L16.3536 12.8536ZM4 13L16 13L16 12L4 12L4 13Z' fill='%23AAAAAA'/%3E%3Cpath d='M2.64645 6.64645C2.45118 6.84171 2.45118 7.15829 2.64645 7.35355L5.82843 10.5355C6.02369 10.7308 6.34027 10.7308 6.53553 10.5355C6.7308 10.3403 6.7308 10.0237 6.53553 9.82843L3.70711 7L6.53553 4.17157C6.7308 3.97631 6.7308 3.65973 6.53553 3.46447C6.34027 3.2692 6.02369 3.2692 5.82843 3.46447L2.64645 6.64645ZM15 6.5L3 6.5L3 7.5L15 7.5L15 6.5Z' fill='%23AAAAAA'/%3E%3C/svg%3E%0A");
}

.mobile-only {
    padding-top: 2px;
    padding-bottom: 2px;
    margin: 0;
}

.extra-space {
    margin-bottom: 15px;
}


/*
   Page Bar
*/

.pageBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.pageBar.on-bottom {
    padding-top: 20px;
}

.buttonWrapper {
    position: sticky;
    left: 100%;
    bottom: 20px;
    z-index: 5100;
    margin-top: -20px;
}

.buttonTop {
    position: fixed;
    right: 50%;
    bottom: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 66px;
    background: #26AAAA;
    font-size: 12px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    border: 0;
    transition: opacity .4s ease-in-out;
    cursor: pointer;
    font-family: "myriad-pro", sans-serif;
    font-weight: 600;
    z-index: 5000;
}

.buttonTop::before {
    content: '';
    display: inline-block;
    background: transparent none no-repeat 50% 50%;
    background-size: contain;
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.5 7.625L8 3.125L12.5 7.625' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 3.75V12.875' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.itemsPerPageWrapper {
    font-size: 14px;
    color: #2A2A2A;
}

.itemsPerPageWrapper.select {
    display: inline-block;
    appearance: none;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 1.75L5 5.25L8.5 1.75' stroke='%2326AAAA' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: calc(100% - 5px) 50%;
    background-repeat: no-repeat;
    border: 1px solid #AAAAAA;
    border-radius: 4px;
    padding: 0 25px 0 10px;
    margin: 0 5px;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    outline: none;
    line-height: 27px;
}

.separator {
    display: inline-block;
    width: 19px;
    height: 19px;
    background: transparent none no-repat 50% 50%;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.3536 12.8536C16.5488 12.6583 16.5488 12.3417 16.3536 12.1464L13.1716 8.96447C12.9763 8.7692 12.6597 8.7692 12.4645 8.96447C12.2692 9.15973 12.2692 9.47631 12.4645 9.67157L15.2929 12.5L12.4645 15.3284C12.2692 15.5237 12.2692 15.8403 12.4645 16.0355C12.6597 16.2308 12.9763 16.2308 13.1716 16.0355L16.3536 12.8536ZM4 13L16 13L16 12L4 12L4 13Z' fill='%232A2A2A'/%3E%3Cpath d='M2.64645 6.64645C2.45118 6.84171 2.45118 7.15829 2.64645 7.35355L5.82843 10.5355C6.02369 10.7308 6.34027 10.7308 6.53553 10.5355C6.7308 10.3403 6.7308 10.0237 6.53553 9.82843L3.70711 7L6.53553 4.17157C6.7308 3.97631 6.7308 3.65973 6.53553 3.46447C6.34027 3.2692 6.02369 3.2692 5.82843 3.46447L2.64645 6.64645ZM15 6.5L3 6.5L3 7.5L15 7.5L15 6.5Z' fill='%232A2A2A'/%3E%3C/svg%3E%0A");
}

.separator.separator-clear {
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.3536 12.8536C16.5488 12.6583 16.5488 12.3417 16.3536 12.1464L13.1716 8.96447C12.9763 8.7692 12.6597 8.7692 12.4645 8.96447C12.2692 9.15973 12.2692 9.47631 12.4645 9.67157L15.2929 12.5L12.4645 15.3284C12.2692 15.5237 12.2692 15.8403 12.4645 16.0355C12.6597 16.2308 12.9763 16.2308 13.1716 16.0355L16.3536 12.8536ZM4 13L16 13L16 12L4 12L4 13Z' fill='%23AAAAAA'/%3E%3Cpath d='M2.64645 6.64645C2.45118 6.84171 2.45118 7.15829 2.64645 7.35355L5.82843 10.5355C6.02369 10.7308 6.34027 10.7308 6.53553 10.5355C6.7308 10.3403 6.7308 10.0237 6.53553 9.82843L3.70711 7L6.53553 4.17157C6.7308 3.97631 6.7308 3.65973 6.53553 3.46447C6.34027 3.2692 6.02369 3.2692 5.82843 3.46447L2.64645 6.64645ZM15 6.5L3 6.5L3 7.5L15 7.5L15 6.5Z' fill='%23AAAAAA'/%3E%3C/svg%3E%0A");
}


/*
   Button back to top
*/

.buttonWrapper {
    opacity: 1;
    pointer-events: all;
}

.buttonWrapper.hide {
    opacity: 0;
    pointer-events: none;
}

.buttonTop {
    position: absolute;
    bottom: 0px;
    right: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 66px;
    background: #26AAAA;
    font-size: 12px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    border: 0;
    transition: opacity .4s ease-in-out;
    cursor: pointer;
    font-family: "myriad-pro", sans-serif;
    font-weight: 600;
    z-index: 5000;
}

.buttonTop::before {
    content: '';
    display: inline-block;
    background: transparent none no-repeat 50% 50%;
    background-size: contain;
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.5 7.625L8 3.125L12.5 7.625' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 3.75V12.875' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}